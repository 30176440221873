<template>
  <div>
    <div>
      <system-bar></system-bar>
      <admin-menu></admin-menu>
    </div>
    <div>
      <v-card>
        <v-card-text>
          <signup-form
            @signupSuccess="signupSuccess"
            v-bind:id="doctorId"
            v-bind:userType="userType"
            v-bind:memberType="memberType"
          >
          </signup-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import SystemBar from "../../../library/system-bar/system-bar";
import AdminMenu from "../../menubar/menubar";
import SignupForm from "../../../admin/sign-up/sign-up";
export default {
  components: {
    "signup-form": SignupForm,
    "admin-menu": AdminMenu,
    "system-bar": SystemBar,
  },
  name: "CreateDoctor",
  data() {
    return {
      url: process.env.VUE_APP_LOYALTY_API_URI,
      custId: process.env.VUE_APP_OGF_CUST_ID,
      doctorId: "",
      userType: "doctor",
      memberType: "Doctor",
    };
  },
  mounted() {
    //console.log("0000000000000000");
    //console.log(this.$route.params.id);
    if (typeof this.$route.params.id !== "undefined") {
      this.doctorId = this.$route.params.id;
    }
  },
  methods: {
    signupSuccess(response) {
      console.log(response);
      alert("Saved");
      this.$router.back();
    },
  },
  watch: {
    doctorId: {
      handler() {
        //console.log(val);
      },
    },
  },
};
</script>
