<template>
  <v-container>
    <v-row justify="space-around">
      <v-card max-width="960" ref="form">
        <v-card-title>
          Sign up <v-spacer></v-spacer
          ><v-btn @click="back">&lt; Back </v-btn></v-card-title
        >

        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="8" offset-md="2">
                <v-text-field
                  v-model="regForm.clinic.clinicName"
                  :rules="nameRules"
                  label="Clinic Name"
                  required
                  id="clinicName"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8" offset-md="2">
                <v-text-field
                  v-model="regForm.clinic.clinicTelNo"
                  label="Clinic Tel."
                  :rules="[(v) => !!v || 'This field is required']"
                  required
                  id="clinicTelNo"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8" offset-md="2">
                <v-text-field
                  v-model="regForm.firstname"
                  :rules="nameRules"
                  label="First Name"
                  required
                  id="firstname"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="8" offset-md="2">
                <v-text-field
                  v-model="regForm.lastname"
                  :rules="nameRules"
                  label="Last Name(Family Name/Surname)"
                  required
                  id="lastname"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="8" offset-md="2">
                <v-text-field
                  v-model="regForm.email"
                  :rules="emailRules"
                  label="Email"
                  type="email"
                  required
                  id="email"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="8" offset-md="2">
                <v-select
                  v-model="regForm.practitioner"
                  :items="items"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Practitioner"
                  required
                  id="practitioner"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6">
                <v-text-field
                  v-model="regForm.mobile"
                  :rules="mobileRules"
                  label="Mobile"
                  required
                  id="mobile"
                ></v-text-field>
              </v-col>
              <v-col md="6">
                <v-text-field
                  v-model="regForm.licenseId"
                  label="Doctor License ID"
                  required
                  :rules="[(v) => !!v || 'This field is required']"
                  id="licenseId"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="typeof id === 'undefined'">
              <v-col md="6" sm="12">
                <v-text-field
                  v-model="regForm.password"
                  :counter="12"
                  :rules="passwordRules"
                  label="Password"
                  required
                  type="password"
                  maxlength="12"
                  id="password"
                  ref="password"
                ></v-text-field>
              </v-col>
              <v-col md="6" sm="12">
                <v-text-field
                  v-model="regForm.confirmPassword"
                  label="Confirm Password"
                  :rules="confirmPasswordRules"
                  required
                  type="password"
                  maxlength="12"
                  id="confirmPassword"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-title>Shipping Address/Clinic Address</v-card-title>

        <v-card-text>
          <address-form
            @updateAddress="updateAddress"
            @updateValid="updateValid"
            v-bind:chkValid="chkValid"
            v-bind:editAddress="editAddress"
          ></address-form>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="regForm.vip"
                  label="VIP"
                  :value="regForm.vip"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="regForm.usd"
                  label="Pay by USD"
                  :value="regForm.usd"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="regForm.influencer"
                  label="Influencer"
                  :value="regForm.influencer"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="regForm.discount"
                  label="Discount %"
                  maxlength="4"
                  id="discount"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="8" offset-md="2">
                <v-select
                  v-model="regForm.status"
                  :items="doctor_status"
                  label="Status"
                  required
                  id="status"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn :disabled="!valid" class="mr-4" @click="validate"> Save </v-btn>

                <v-btn class="mr-4" @click="reset"> Reset </v-btn>

                <!--v-btn color="warning" @click="resetValidation"> Reset Validation </!--v-btn-->
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import AddressForm from "../../library/postcode/postcode-form.vue";
import axios from "axios";
export default {
  components: {
    "address-form": AddressForm,
  },
  props: {
    memberType: {
      type: String,
    },
    userType: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data: () => ({
    editAddress: {},
    url: process.env.VUE_APP_LOYALTY_API_URI,
    custId: process.env.VUE_APP_OGF_CUST_ID,
    responseStatus: {},
    responseData: {},
    chkValid: false,
    valid: true,
    agree: false,
    //url: process.env.VUE_APP_LOYALTY_API_URI,
    regForm: {
      clinic: { clinicName: "", clinicTelNo: "" },
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      practitioner: "",
      licenseId: "",
      password: "",
      confirmPassword: "",
      vip: 0,
      usd: 0,
      influencer: 0,
      discount: 0,
      status: "",
    },
    hasError: false,
    address: {
      address: "",
      province: "",
      district: "",
      tambon: "",
      postcode: "",
      zipcode: "",
    },
    info: {},
    name: "",
    nameRules: [
      (v) => !!v || "This field is required",
      //(v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /[^@]+@[^@]+.[a-zA-Z]{2,6}/.test(v) || "E-mail must be valid",
    ],
    mobile: "",
    mobileRules: [
      (v) => !!v || "Mobile is required",
      (v) => /(^0)(6|8|9)[0-9]{8,8}/.test(v) || "Mobile must be valid",
    ],
    password: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{7,15}$/.test(
          v
        ) ||
        "Password must have Numberic, Lowercase, Uppercase, special charactor ( !@#$%^&* ) and must between 6-12 charactors ",
    ],
    confirmPassword: "",
    confirmPasswordRules: [
      (v) => !!v || "Confirm password is required",
      (v) => {
        let pwd = document.getElementById("password");
        if (pwd != "undefined") {
          if (document.getElementById("password").value == v) {
            return true;
          } else {
            return "Confirm password must equal password ";
          }
        }
      },
    ],

    requiredRules: [(v) => !!v || "This field is required"],
    items: ["Dentist", "Orthodontist", "Other Specialist"],
    doctor_status: ["Inactive", "Waiting", "Active"],
  }),
  computed: {},
  mounted() {
    console.log("---mounted--");
    console.log(this.id);
    // console.log(this.userType);
    this.id = this.$route.params.id;

    if (typeof this.id !== "undefined") {
      this.init();
    }
  },
  methods: {
    requiredFields() {
      return {
        custId: process.env.VUE_APP_OGF_CUST_ID,
        clinic: {
          clinicName: this.regForm.clinic.clinicName,
          clinicTelNo: this.regForm.clinic.clinicTelNo,
          address: this.address.address,
          postcode: this.address.postcode,
          province: this.address.province,
          district: this.address.district,
          tambon: this.address.tambon,
        },
        firstname: this.regForm.firstname,
        lastname: this.regForm.lastname,
        mobile: this.regForm.mobile,
        email: this.regForm.email,
        practitioner: this.regForm.practitioner,
        licenseId: this.regForm.licenseId,
        password: this.regForm.password,
        address: this.address.address,
        postcode: this.address.postcode,
        province: this.address.province,
        district: this.address.district,
        tambon: this.address.tambon,
        member_type: this.memberType,
        vip: this.regForm.vip,
        usd: this.regForm.usd,
        influencer: this.regForm.influencer,
        discount: this.regForm.discount,
        status: this.regForm.status,
      };
    },
    async init() {
      try {
        let payload = {
          doctorId: this.id,
        };
        console.log(payload);
        await axios
          .post(
            this.url + "/api/bfc/v1/" + this.userType + "/getInfo",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response);

            if (response.data.statusCode == 0) {
              this.responseStatus = {
                status: "Success",
                code: 0,
                color: "green",
                type: "success",
                text: "สำเร็จ",
                alertStatus: true,
              };

              this.info = response.data.data[0];
              console.log(this.info);
              this.regForm.vip = this.info.vip;
              this.regForm.usd = this.info.usd;
              this.regForm.influencer = this.info.influencer;
              this.regForm.status = this.info.status;
              let clinic = this.info.clinic;
              this.editAddress = this.info.shippingAddress.address;
              this.editAddress = {
                clinicAddress: clinic.address,
                /*postcode: clinic.postcode,
                province: clinic.province,
                tambon: clinic.tambon,
                district: clinic.district,
                */
              };
              
              //this.address = this.info.clinic;

              //console.log(this.editAddress);

              //this.$router.back();
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    async validate() {
      this.hasError = false;
      let breakForEachException = {};
      let uri = "";
      // typeof this.editAddress.clinicAddress === "undefined"
      //   ? "/api/bfc/v1/doctor/signup"
      //   : "/api/bfc/v1/doctor/update-profile";

      try {
        await Object.keys(this.regForm).forEach((f) => {
          //console.log(this.requiredField[f]);

          if (!this.regForm[f]) {
            if (
              typeof this.id === "undefined" &&
              (f == "password" || f == "confirmPassword")
            ) {
              this.hasError = true;
              document.getElementById(f).focus();
              throw breakForEachException;
            }
          }
        });
        //console.log("validation");
        console.log(this.id);
        this.chkValid = true;
        let payload = {};
        if (typeof this.id !== "undefined") {
          uri = "/api/bfc/v1/admin/users/" + this.userType + "/updateProfile";
          payload = {
            doctorId: this.id,
            memberId: this.memberId,
            member: this.requiredFields(),
            doctor: this.requiredFields(),
          };
        } else {
          uri = "/api/bfc/v1/" + this.userType + "/signup";
          payload = {
            ...this.requiredFields(),
          };
        }

        if (!this.hasError) {
          //this.valid = true;
          // send
          // let payload = {
          //   doctorId: this.id,
          //   memberId: this.memberId,
          //   member: this.requiredFields(),
          //   doctor: this.requiredFields(),
          //   clinic: this.requiredFields(),
          // };
          await axios
            .post(
              this.url + uri,
              payload, // need to change
              {
                headers: {
                  // Overwrite Axios's automatically set Content-Type
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              console.log(response);

              if (response.statusCode == 0) {
                this.responseStatus = {
                  status: "Success",
                  code: 0,
                  color: "green",
                  type: "success",
                  text: "สำเร็จ",
                  alertStatus: true,
                };

                this.responseData = response.data.data;
              }
            });
        }
        this.$emit("signupSuccess", this.responseData);
      } catch (e) {
        if (e != breakForEachException) throw e;
      }
    },
    reset() {
      this.regForm = {};
      this.address = {};
    },
    resetValidation() {},
    updateAddress(addr) {
      this.address = {
        address: addr.address,
        postcode: addr.postcode,
        province: addr.province,
        district: addr.district,
        zipcode: addr.postcode,
        tambon: addr.tambon,
      };
      console.log(this.address);
    },
    updateValid(val) {
      this.chkValid = val;
    },
    back() {
      this.$router.back();
    },
  },
  watch: {
    address: {
      handler() {
        console.log(this.address);
      },
    },
    info: {
      handler(val) {
        console.log(val);
        this.regForm = val;
        this.address = {
          address: val.clinic.address,
          postcode: val.clinic.postcode,
          zipcode: val.clinic.postcode,
          province: val.clinic.province,
          district: val.clinic.district,
          tambon: val.clinic.tambon,
        };
        console.log(this.regForm);
      },
    },
  },
};
</script>
